<script>
import { SessionHelper } from '../services/Session';
export default {
  name: "Login",
  created() {
    SessionHelper.setCookie("X-Auth-Id", this.$route.params.id)
    location.href = "/";
  }
};
</script>
